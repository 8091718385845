<template>
  <v-app>
    <v-card>
      <v-card-title
        :class="{
          title: $vuetify.breakpoint.smAndUp,
          titlesmall: $vuetify.breakpoint.xsOnly
        }"
      >
        <v-btn
          absolute
          top
          right
          icon
          @click="form.language = 'HR'"
          class="mr-10"
        >
          <v-img width="24px" src="../assets/hr-flag-icon.png"></v-img>
        </v-btn>
        <v-btn absolute top right icon @click="form.language = 'GB'">
          <v-img width="24px" src="../assets/gb-flag-icon.png"></v-img>
        </v-btn>
        <!--      <div>
        {{
          form.event != null
            ? eventName
            : form.language == "HR"
            ? "Prijava na događaj"
            : "Event registration"
        }}
        </div>-->
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="12" lg="10">
              <v-stepper v-model="step" vertical>
                <v-stepper-step step="1">{{
                  form.language == "HR" ? "Odabir događaja" : "Select event"
                }}</v-stepper-step>
                <v-stepper-content step="1">
                  <div>
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <v-list>
                            <v-list-item-group v-model="form.event">
                              <v-list-item
                                v-for="e in events"
                                v-bind:key="e.id"
                                :value="e.id"
                                two-line
                              >
                                <v-list-item-content>
                                  <v-list-item-title class="itemtitle">{{
                                    form.language == "HR"
                                      ? e.name_hr
                                      : e.name_en
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle class="itemsub">{{
                                    formatDate(e.date_from) +
                                      " - " +
                                      formatDate(e.date_to) +
                                      ", " +
                                      (form.language == "HR"
                                        ? e.location_hr
                                        : e.location_en)
                                  }}</v-list-item-subtitle>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-stepper-content>

                <v-stepper-step step="2">{{
                  form.language == "HR" ? "Vrsta prijave" : "Registration type"
                }}</v-stepper-step>
                <v-stepper-content step="2">
                  <div>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-list subheader three-line flat>
                            <v-list-item-group v-model="form.type">
                              <v-list-item>
                                <template v-slot:default="{ active }">
                                  <v-list-item-action>
                                    <v-checkbox
                                      :input-value="active"
                                      color="primary"
                                    ></v-checkbox>
                                  </v-list-item-action>

                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="itemtitle"
                                      v-html="
                                        form.language == 'HR'
                                          ? 'Individualna privatna prijava'
                                          : 'Individual private registration'
                                      "
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                      class="itemsub"
                                      v-html="
                                        form.language == 'HR'
                                          ? 'Želim se samostalno prijaviti.<br />Svoju kotizaciju platit ću kao privatna osoba.'
                                          : 'I want to register for myself.<br/>I will pay my conference fee as a private person.'
                                      "
                                    ></v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>

                              <v-list-item>
                                <template v-slot:default="{ active }">
                                  <v-list-item-action>
                                    <v-checkbox
                                      :input-value="active"
                                      color="primary"
                                    ></v-checkbox>
                                  </v-list-item-action>

                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="itemtitle"
                                      v-html="
                                        form.language == 'HR'
                                          ? 'Individualna prijava kao zaposlenik tvrtke'
                                          : 'Individual registration as a company employee'
                                      "
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                      class="itemsub"
                                      v-html="
                                        form.language == 'HR'
                                          ? 'Želim se samostalno prijaviti.<br />Moju kotizaciju platit će tvrtka u kojoj sam zaposlen.'
                                          : 'I want to register for myself.<br/>My coference fee will be paid by the company I work for.'
                                      "
                                    >
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>

                              <v-list-item>
                                <template v-slot:default="{ active }">
                                  <v-list-item-action>
                                    <v-checkbox
                                      :input-value="active"
                                      color="primary"
                                    ></v-checkbox>
                                  </v-list-item-action>

                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="itemtitle"
                                      v-html="
                                        form.language == 'HR'
                                          ? 'Skupna prijava *'
                                          : 'Group registration *'
                                      "
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                      class="itemsub"
                                      v-if="form.language == 'HR'"
                                    >
                                      Želim prijaviti više sudionika iz svoje
                                      tvrtke.<br />Kotizaciju će platiti tvrtka.
                                      <br /><span class="text--caption"
                                        >* Ispunjava administrator/ica
                                        tvrtke</span
                                      >
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle
                                      class="itemsub"
                                      v-else
                                    >
                                      I want to register multiple participants
                                      from my company.<br />Conference fee will
                                      be paid by the company. <br /><span
                                        class="text--caption"
                                        >* Filled in by the company
                                        administrator</span
                                      >
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-col>
                        <v-col cols="12">
                          <v-slide-x-transition>
                            <v-btn
                              rounded
                              dark
                              color="success"
                              v-show="form.type != null"
                              @click="incStep"
                              class="ma-2"
                              >{{
                                form.language == "HR"
                                  ? "Sljedeći korak"
                                  : "Next step"
                              }}</v-btn
                            >
                          </v-slide-x-transition>
                          <v-btn
                            class="ma-2"
                            rounded
                            color="info"
                            dark
                            @click="decStep"
                            >{{
                              form.language == "HR"
                                ? "Povratak"
                                : "Previous step"
                            }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-stepper-content>

                <v-stepper-step step="3">{{
                  form.language == "HR"
                    ? "Osobni podaci " +
                      (form.type == 2 ? "(administrator/ica tvrtke)" : "")
                    : "Personal data " +
                      (form.type == 2 ? "(company administrator)" : "")
                }}</v-stepper-step>
                <v-stepper-content step="3">
                  <div>
                    <v-form v-model="validUserData">
                      <v-container>
                        <v-row>
                          <v-col v-if="form.type == 2" cols="4" class="py-1">
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR' ? 'Titula' : 'Title'
                              "
                              :rules="stringRules"
                              v-model="form.contact_title"
                            ></v-text-field>
                          </v-col>
                          <v-col v-else cols="4" class="py-1">
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR' ? 'Titula' : 'Title'
                              "
                              :rules="stringRules"
                              v-model="form.participants[0].title"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            v-if="form.type == 2"
                            cols="12"
                            lg="6"
                            class="py-1"
                          >
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR' ? 'Ime *' : 'First name *'
                              "
                              :rules="stringRequiredRules"
                              v-model="form.contact_first_name"
                            ></v-text-field>
                          </v-col>
                          <v-col v-else cols="12" lg="6" class="py-1">
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR' ? 'Ime *' : 'First name *'
                              "
                              :rules="stringRequiredRules"
                              v-model="form.participants[0].first_name"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            v-if="form.type == 2"
                            cols="12"
                            lg="6"
                            class="py-1"
                          >
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR'
                                  ? 'Prezime *'
                                  : 'Last name *'
                              "
                              :rules="stringRequiredRules"
                              v-model="form.contact_last_name"
                            ></v-text-field>
                          </v-col>
                          <v-col v-else cols="12" lg="6" class="py-1">
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR'
                                  ? 'Prezime *'
                                  : 'Last name *'
                              "
                              :rules="stringRequiredRules"
                              v-model="form.participants[0].last_name"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            v-if="form.type == 2"
                            cols="12"
                            lg="6"
                            class="py-1"
                          >
                            <v-text-field
                              dense
                              label="E-mail *"
                              :rules="emailRequiredRules"
                              v-model="form.contact_email"
                            ></v-text-field>
                          </v-col>
                          <v-col v-else cols="12" lg="6" class="py-1">
                            <v-text-field
                              dense
                              label="E-mail *"
                              :rules="emailRequiredRules"
                              v-model="form.participants[0].email"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            v-if="form.type == 2"
                            cols="12"
                            lg="6"
                            class="py-1"
                          >
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR' ? 'Telefon' : 'Phone'
                              "
                              :rules="phoneRules"
                              v-model="form.contact_phone"
                            ></v-text-field>
                          </v-col>
                          <v-col v-else cols="12" lg="6" class="py-1">
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR' ? 'Telefon' : 'Phone'
                              "
                              :rules="phoneRules"
                              v-model="form.participants[0].phone"
                            ></v-text-field>
                          </v-col>
                          <v-col v-if="form.type != 2" cols="12" class="py-1">
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR'
                                  ? 'Ulica i kućni broj'
                                  : 'Address'
                              "
                              :rules="stringRules"
                              v-model="form.participants[0].address"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            v-if="form.type != 2"
                            cols="12"
                            lg="4"
                            class="py-1"
                          >
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR'
                                  ? 'Poštanski broj'
                                  : 'Zip code'
                              "
                              :rules="stringRules"
                              v-model="form.participants[0].postal_code"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            v-if="form.type != 2"
                            cols="12"
                            lg="4"
                            class="py-1"
                          >
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR'
                                  ? 'Mjesto/Grad'
                                  : 'Place/City'
                              "
                              :rules="stringRules"
                              v-model="form.participants[0].city"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            v-if="form.type != 2"
                            cols="12"
                            lg="4"
                            class="py-1"
                          >
                            <CountryInput
                              required
                              dense
                              :label="
                                form.language == 'HR' ? 'Država *' : 'Country *'
                              "
                              :model.sync="form.participants[0].country"
                            ></CountryInput>
                          </v-col>
                          <v-col
                            v-if="form.type != 2"
                            cols="12"
                            lg="4"
                            class="py-1"
                          >
                            <div>
                              {{
                                form.language == "HR"
                                  ? "Napomena: nakon što kliknete ispod za upis datuma rođenja te se otvori kalendar, za brži odabir datuma, kliknite na godinu u gornjem lijevom kutu"
                                  : "Note: after clicking below to enter the date of birth and the calendar opens, to select the date faster, click on the year in the upper left corner"
                              }}
                            </div>
                            <DateInput
                              dense
                              required
                              :label="
                                form.language == 'HR'
                                  ? 'Datum rođenja *'
                                  : 'Birthday *'
                              "
                              :model.sync="form.participants[0].birthday"
                              resetButton
                            ></DateInput>
                          </v-col>
                          <v-col
                            v-if="
                              form.type == 0 &&
                                form.participants[0].country == 'HR'
                            "
                            cols="12"
                            class="py-1"
                          >
                            <v-checkbox
                              v-model="isStudent"
                              :label="
                                form.language == 'HR'
                                  ? 'U statusu sam studenta preddiplomskog ili diplomskog studija u Republici Hrvatskoj'
                                  : 'My current status is undergraduate or postgraduate student in the Republic Of Croatia'
                              "
                            >
                            </v-checkbox>
                          </v-col>
                          <v-col
                            v-if="
                              form.type == 0 &&
                                isStudent &&
                                form.participants[0].country == 'HR'
                            "
                            cols="12"
                            lg="8"
                            class="py-1"
                          >
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR'
                                  ? 'Naziv i adresa fakulteta *'
                                  : 'Name and address of Faculty *'
                              "
                              :rules="stringRequiredRules"
                              v-model="form.participants[0].faculty"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            v-if="
                              form.type == 0 &&
                                isStudent &&
                                form.participants[0].country == 'HR'
                            "
                            cols="12"
                            lg="4"
                            class="py-1"
                          >
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR'
                                  ? 'Broj indeksa *'
                                  : 'Index number *'
                              "
                              :rules="stringRequiredRules"
                              v-model="form.participants[0].index_num"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <div class="text-caption">
                              {{
                                form.language == "HR"
                                  ? "* Obavezna polja"
                                  : "* Required fields"
                              }}
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <v-slide-x-transition>
                              <v-btn
                                rounded
                                dark
                                color="success"
                                v-show="validUserData"
                                @click="incStep"
                                class="ma-2"
                                >{{
                                  form.language == "HR"
                                    ? "Sljedeći korak"
                                    : "Next step"
                                }}</v-btn
                              >
                            </v-slide-x-transition>
                            <v-btn
                              class="ma-2"
                              rounded
                              color="info"
                              dark
                              @click="decStep"
                              >{{
                                form.language == "HR"
                                  ? "Povratak"
                                  : "Previous step"
                              }}</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </div>
                </v-stepper-content>

                <v-stepper-step v-if="form.type != 0" step="4">{{
                  form.language == "HR" ? "Podaci o tvrtki" : "Company data"
                }}</v-stepper-step>
                <v-stepper-content v-if="form.type != 0" step="4">
                  <div>
                    <v-form v-model="validCompanyData">
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            lg="4"
                            class="py-1"
                            v-if="form.language == 'HR'"
                          >
                            <OibInput
                              label="OIB *"
                              :model.sync="form.company_oib"
                              dense
                              required
                            ></OibInput>
                          </v-col>
                          <v-col v-else cols="12" lg="4" class="py-1">
                            <v-text-field
                              label="VAT ID *"
                              v-model="form.company_oib"
                              dense
                              :rules="stringRequiredRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" lg="6" class="py-1">
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR'
                                  ? 'Naziv tvrtke *'
                                  : 'Company name *'
                              "
                              :rules="stringRequiredRules"
                              v-model="form.company_name"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6" class="py-1">
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR'
                                  ? 'Odjel/Org. jedinica * (upišite \'-\' ako nije primjenjivo)'
                                  : 'Company unit * (enter \'-\' if it is not applicable)'
                              "
                              :rules="stringRequiredRules"
                              v-model="form.company_unit"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6" class="py-1">
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR'
                                  ? 'E-mail na koji se šalje ponuda *'
                                  : 'E-mail to be used to send quote *'
                              "
                              :rules="emailRequiredRules"
                              v-model="form.company_email"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6" class="py-1">
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR'
                                  ? 'Ulica i kućni broj *'
                                  : 'Address *'
                              "
                              :rules="stringRequiredRules"
                              v-model="form.company_address"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="4" class="py-1">
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR'
                                  ? 'Poštanski broj *'
                                  : 'Zip code *'
                              "
                              :rules="stringRequiredRules"
                              v-model="form.company_postal_code"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="4" class="py-1">
                            <v-text-field
                              dense
                              :label="
                                form.language == 'HR'
                                  ? 'Mjesto/Grad *'
                                  : 'Place/City *'
                              "
                              :rules="stringRequiredRules"
                              v-model="form.company_city"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="4" class="py-1">
                            <CountryInput
                              dense
                              required
                              :label="
                                form.language == 'HR' ? 'Država *' : 'Country *'
                              "
                              :model.sync="form.company_country"
                            ></CountryInput>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <div class="text-caption">
                              {{
                                form.language == "HR"
                                  ? "* Obavezna polja"
                                  : "* Required fields"
                              }}
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <v-slide-x-transition>
                              <v-btn
                                rounded
                                dark
                                color="success"
                                v-show="validCompanyData"
                                @click="incStep"
                                class="ma-2"
                                >{{
                                  form.language == "HR"
                                    ? "Sljedeći korak"
                                    : "Next step"
                                }}</v-btn
                              >
                            </v-slide-x-transition>
                            <v-btn
                              class="ma-2"
                              rounded
                              color="info"
                              dark
                              @click="decStep"
                              >{{
                                form.language == "HR"
                                  ? "Povratak"
                                  : "Previous step"
                              }}</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </div>
                </v-stepper-content>

                <v-stepper-step v-if="form.type == 2" step="5">{{
                  form.language == "HR"
                    ? "Sudionici iz tvrtke"
                    : "Participants from company"
                }}</v-stepper-step>
                <v-stepper-content v-if="form.type == 2" step="5">
                  <div>
                    <ParticipantRegistration
                      v-for="participant in form.participants"
                      :key="participant.id"
                      :form.sync="form.participants[participant.id - 1]"
                      :language="form.language"
                      :id="participant.id"
                      :ccountry="form.company_country"
                      :formValid.sync="
                        validParticipantsData[participant.id - 1]
                      "
                      :fees="fees"
                      :services="services"
                    >
                    </ParticipantRegistration>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            rounded
                            dark
                            color="secondary"
                            @click="addParticipant"
                            class="ma-2"
                            >{{
                              form.language == "HR"
                                ? "Dodaj sudionika"
                                : "Add participant"
                            }}</v-btn
                          >
                          <v-slide-x-transition>
                            <v-btn
                              v-show="form.participants.length > 1"
                              rounded
                              dark
                              color="secondary"
                              @click="deleteParticipant"
                              class="ma-2"
                              >{{
                                form.language == "HR"
                                  ? "Briši zadnjeg sudionika"
                                  : "Delete last participant"
                              }}</v-btn
                            >
                          </v-slide-x-transition>
                          <v-slide-x-transition>
                            <v-btn
                              rounded
                              dark
                              color="success"
                              v-show="checkValidParticipantsData"
                              @click="incStep"
                              class="ma-2"
                              >{{
                                form.language == "HR"
                                  ? "Sljedeći korak"
                                  : "Next step"
                              }}</v-btn
                            >
                          </v-slide-x-transition>
                          <v-btn
                            class="ma-2"
                            rounded
                            color="info"
                            dark
                            @click="decStep"
                            >{{
                              form.language == "HR"
                                ? "Povratak"
                                : "Previous step"
                            }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-stepper-content>

                <v-stepper-step
                  v-if="form.type != 2"
                  :step="form.type != 0 ? 5 : 4"
                  >{{
                    form.language == "HR"
                      ? "Kotizacija i usluge"
                      : "Fee and services"
                  }}</v-stepper-step
                >
                <v-stepper-content
                  v-if="form.type != 2"
                  :step="form.type != 0 ? 5 : 4"
                >
                  <div>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          {{ form.language == "HR" ? "Kotizacija" : "Fee" }}
                          <v-list subheader three-line flat>
                            <v-list-item-group
                              v-model="form.participants[0].fee"
                            >
                              <v-list-item
                                v-for="fee in ffees"
                                :key="fee.id"
                                :value="fee.id"
                              >
                                <template v-slot:default="{ active }">
                                  <v-list-item-action>
                                    <v-checkbox
                                      :input-value="active"
                                      color="primary"
                                    ></v-checkbox>
                                  </v-list-item-action>

                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="itemtitle"
                                      v-html="
                                        (form.language == 'HR'
                                          ? fee.name_hr
                                          : fee.name_en) +
                                          '<br/><b>' +
                                          formatAmount(fee.amount) +
                                          '</b>'
                                      "
                                    >
                                    </v-list-item-title>
                                    <v-list-item-subtitle
                                      class="itemsub"
                                      v-html="
                                        form.language == 'HR'
                                          ? fee.description_hr
                                          : fee.description_en
                                      "
                                    ></v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                          <div class="red--text">
                            {{
               form.language == "HR"
                                ? "Istaknute cijene su bez PDV-a"
                                : "Prices do not include VAT"
              }}
              <br/>
              {{ conversionRate() }}
                          </div>
                        </v-col>
                        <v-col cols="12">
                          {{
                            form.language == "HR"
                              ? "Odaberite usluge uključene u kotizaciju:"
                              : "Select services included in registration fee"
                          }}
                          <v-list flat>
                            <v-list-item-group
                              v-model="form.participants[0].services"
                              multiple
                            >
                              <v-list-item
                                v-for="service in services"
                                :key="service.id"
                                :value="service.id"
                              >
                                <template v-slot:default="{ active }">
                                  <v-list-item-action>
                                    <v-checkbox
                                      :input-value="active"
                                      color="primary"
                                    ></v-checkbox>
                                  </v-list-item-action>

                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="itemtitle"
                                      v-html="
                                        form.language == 'HR'
                                          ? service.name_hr
                                          : service.name_en
                                      "
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-col>
                        <v-col cols="12">
                          <v-slide-x-transition>
                            <v-btn
                              rounded
                              dark
                              color="success"
                              v-show="form.participants[0].fee != null"
                              @click="incStep"
                              class="ma-2"
                              >{{
                                form.language == "HR"
                                  ? "Sljedeći korak"
                                  : "Next step"
                              }}</v-btn
                            >
                          </v-slide-x-transition>
                          <v-btn
                            class="ma-2"
                            rounded
                            color="info"
                            dark
                            @click="decStep"
                            >{{
                              form.language == "HR"
                                ? "Povratak"
                                : "Previous step"
                            }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-stepper-content>

                <v-stepper-step :step="form.type != 0 ? 6 : 5">{{
                  form.language == "HR"
                    ? "Završetak prijave i ponuda za plaćanje"
                    : "Payment quote"
                }}</v-stepper-step>
                <v-stepper-content :step="form.type != 0 ? 6 : 5">
                  <div>
                    <v-container>
                      <v-row justify="center">
                        <v-col cols="12">
                          {{
                            form.language == "HR"
                              ? "Ukupan iznos za plaćanje:"
                              : "Total amount for payment:"
                          }}<b> {{ totalAmount }}</b><br/>{{ conversionRate() }}
                        </v-col>
                        <v-col cols="12">
                          <i>{{
                            form.language == "HR"
                              ? "Stupanjem na snagu Opće uredbe o zaštiti podataka HRO CIGRÉ potvrđuje da će u skladu s GDPR-om osobne podatke sudionika koristiti samo u svrhu prijave i sudjelovanja na događaju " +
                                eventName +
                                " i da ih neće koristiti u druge svrhe."
                              : "HRO CIGRÉ confirms that in accordance with the GDPR, participants' personal data will only be used for the purpose of registering and participating in " +
                                eventName +
                                " event and that it will not be used for any other purposes."
                          }}</i>
                          <br />
                          <v-checkbox
                            :label="
                              form.language == 'HR'
                                ? 'Slažem se da se podaci koje sam naveo/la u ovoj prijavnici koriste u svrhu prijave i sudjelovanja na događaju ' +
                                  eventName
                                : 'I accept that the data I entered in this registration form can be used for the purpose of registering and participating in ' +
                                  eventName +
                                  ' event'
                            "
                            v-model="acceptGdpr"
                          >
                          </v-checkbox>
                        </v-col>
                        <v-col cols="12">
                          <v-slide-x-transition>
                            <v-btn
                              v-if="!registerSuccessful && acceptGdpr"
                              rounded
                              dark
                              color="success"
                              @click="register"
                              class="ma-2"
                              >{{
                                form.language == "HR"
                                  ? "Pošalji prijavu"
                                  : "Register"
                              }}</v-btn
                            >
                          </v-slide-x-transition>
                          <v-btn
                            v-if="!registerSuccessful"
                            rounded
                            class="ma-2"
                            color="info"
                            dark
                            @click="decStep"
                            >{{
                              form.language == "HR"
                                ? "Povratak"
                                : "Previous step"
                            }}</v-btn
                          >
                        </v-col>
                        <v-col cols="12">
                          <v-fade-transition>
                            <span v-if="loading" class="text-h4">{{
                              form.language == "HR"
                                ? "Molimo pričekajte završetak prijave i generiranje ponude za plaćanje"
                                : "Please wait for registration and payment quote generation to finish"
                            }}</span>
                          </v-fade-transition>
                          <v-fade-transition>
                            <v-progress-linear
                              v-if="loading"
                              indeterminate
                              height="12"
                            ></v-progress-linear>
                          </v-fade-transition>
                          <v-slide-x-transition>
                            <v-alert
                              type="success"
                              prominent
                              v-if="registerSuccessful"
                              class="itemtitle"
                            >
                              <span
                                v-html="
                                  form.language == 'HR'
                                    ? 'Zahvaljujemo na registraciji! E-mail s prijavnicom ' +
                                      (totalAmountFloat > 0
                                        ? 'i ponudom za plaćanje '
                                        : '') +
                                      'bit će poslan na Vašu e-mail adresu.'
                                    : 'Thank you for registration! You will receive an e-mail with registration form' +
                                      (totalAmountFloat > 0
                                        ? ' and payment quote'
                                        : '') +
                                      '.'
                                "
                              ></span>
                              <span
                                v-html="
                                  form.language == 'HR'
                                    ? '<br/>U nastavku možete odmah preuzeti prijavnicu' +
                                      (totalAmountFloat > 0
                                        ? ' i ponudu za plaćanje'
                                        : '') +
                                      ':'
                                    : '<br/>Below you can already download registration form' +
                                      (totalAmountFloat > 0
                                        ? ' and payment quote'
                                        : '') +
                                      '.'
                                "
                              ></span>
                              <br /><br />
                              <v-btn
                                rounded
                                dark
                                color="secondary"
                                @click="downloadConfirmation"
                                class="ma-2"
                                ><v-icon
                                  v-if="$vuetify.breakpoint.smAndUp"
                                  class="ma-2"
                                  >picture_as_pdf</v-icon
                                >{{
                                  $vuetify.breakpoint.xsOnly
                                    ? form.language == "HR"
                                      ? "Prijavnica"
                                      : "Reg. form"
                                    : form.language == "HR"
                                    ? "Preuzmi prijavnicu"
                                    : "Download registration form"
                                }}</v-btn
                              >
                              <v-btn
                                v-if="totalAmountFloat > 0"
                                rounded
                                dark
                                color="secondary"
                                @click="downloadQuote"
                                class="ma-2"
                                ><v-icon
                                  v-if="$vuetify.breakpoint.smAndUp"
                                  class="ma-2"
                                  >picture_as_pdf</v-icon
                                >{{
                                  $vuetify.breakpoint.xsOnly
                                    ? form.language == "HR"
                                      ? "Ponuda"
                                      : "Quote"
                                    : form.language == "HR"
                                    ? "Preuzmi ponudu za plaćanje"
                                    : "Download payment quote"
                                }}</v-btn
                              >
                            </v-alert>
                          </v-slide-x-transition>
                          <v-slide-x-transition>
                            <v-alert
                              type="error"
                              prominent
                              v-if="registerFailed"
                              class="itemtitle"
                            >
                              <span
                                v-html="
                                  form.language == 'HR'
                                    ? 'Došlo je do pogreške prilikom slanja prijave na poslužitelj. Molimo pokušajte ponovno kasnije ili se obratite za pomoć na e-mail: central@hro-cigre.hr'
                                    : 'There was an error while sending registration to the server. Please, try again later or contact us for help by e-mail: central@hro-cigre.hr'
                                "
                              ></span>
                            </v-alert>
                          </v-slide-x-transition>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-stepper-content>
              </v-stepper>
            </v-col>
          </v-row>
        </v-container>
        <div id="end"></div>
      </v-card-text>
    </v-card>
  </v-app>
</template>
<script>
import CountryInput from "@/components/CountryInput.vue";
import OibInput from "@/components/OibInput.vue";
import DateInput from "@/components/DateInput.vue";
import ParticipantRegistration from "@/components/ParticipantRegistration.vue";
import moment from "moment";

export default {
  data() {
    return {
      step: 1,
      events: [],
      fees: [],
      services: [],
      form: {
        type: null,
        event: null,
        language: "HR",
        contact_first_name: null,
        contact_last_name: null,
        contact_title: null,
        contact_email: null,
        contact_phone: null,
        company_oib: null,
        company_name: null,
        company_unit: null,
        company_email: null,
        company_address: null,
        company_city: null,
        company_postal_code: null,
        company_country: null,
        participants: [
          {
            id: 1,
            first_name: null,
            last_name: null,
            title: null,
            email: null,
            phone: null,
            address: null,
            city: null,
            postal_code: null,
            country: null,
            fee: null,
            faculty: null,
            index_num: null,
            birthday: null,
            services: []
          }
        ]
      },
      isStudent: false,
      maxLen: 200,
      stringRules: [
        v =>
          v
            ? v.length <= this.maxLen ||
              "Maksimalni dozvoljeni broj znakova je " + this.maxLen
            : true
      ],
      stringRequiredRules: [
        v => !!v || "Ovaj podatak je obavezan",
        v =>
          v
            ? v.length <= this.maxLen ||
              "Maksimalni dozvoljeni broj znakova je " + this.maxLen
            : true
      ],
      emailRules: [
        v =>
          v
            ? v.length <= this.maxLen ||
              "Maksimalni dozvoljeni broj znakova je " + this.maxLen
            : true,
        v =>
          v
            ? v.length == 0 ||
              /^([a-zA-Z0-9-_]+|([a-zA-Z0-9-_]+\.){1,2}[a-zA-Z0-9-_]+)@([a-zA-Z0-9-_]+\.){1,3}[a-z]{2,}$/.test(
                v
              ) ||
              "E-mail nije ispravan"
            : true
      ],
      emailRequiredRules: [
        v => !!v || "Potrebno je upisati e-mail adresu",
        v =>
          v
            ? v.length <= this.maxLen ||
              "Maksimalni dozvoljeni broj znakova je " + this.maxLen
            : true,
        v =>
          v
            ? v.length == 0 ||
              /^([a-zA-Z0-9-_]+|([a-zA-Z0-9-_]+\.){1,2}[a-zA-Z0-9-_]+)@([a-zA-Z0-9-_]+\.){1,3}[a-z]{2,}$/.test(
                v
              ) ||
              "E-mail nije ispravan"
            : true
      ],
      phoneRules: [
        v =>
          v
            ? v.length == 0 ||
              v.length <= 30 ||
              "Maksimalni dozvoljeni broj znakova je 30"
            : true,
        v =>
          v
            ? v.length == 0 ||
              /^[0-9\+\- ]+$/.test(v) ||
              "Neispravan broj telefona."
            : true
      ],
      validUserData: true,
      validCompanyData: true,
      validParticipantsData: [true],
      loading: false,
      registerSuccessful: false,
      registerFailed: false,
      totalAmount: "---",
      uuid: null,
      acceptGdpr: false,
      totalAmountFloat: 0
    };
  },
  components: {
    CountryInput,
    OibInput,
    DateInput,
    ParticipantRegistration
  },
  watch: {
    "form.event": function() {
      if (this.form.event != undefined) this.incStep();
      this.getFees(this.form.event);
      this.getServices(this.form.event);
    }
  },
  computed: {
    checkValidParticipantsData: {
      get() {
        var valid = true;
        for (var i = 0; i < this.validParticipantsData.length; i++)
          if (
            !this.validParticipantsData[i] ||
            this.form.participants[i].fee == null
          )
            valid = false;
        return valid;
      }
    },
    ffees: {
      get() {
        var flist = [];
        var me = this;
        this.fees.forEach(element => {
          if (
            (me.form.participants[0].country == "HR" &&
              me.isStudent &&
              me.form.type == 0 &&
              element.amount == 0) ||
            (!me.isStudent &&
              me.form.type != 2 &&
              me.isYoung(me.form.participants[0].birthday) &&
              element.name_hr.indexOf("35") >= 0 &&
              element.amount > 0) ||
            (!me.isYoung(me.form.participants[0].birthday) &&
              element.amount > 0 &&
              element.name_hr.indexOf("35") == -1)
            //  || (me.form.participants[0].country!="HR" && element.amount>0 && element.name_hr.indexOf('35')==-1)
          ) {
            flist.push(element);
          }
        });
        return flist;
      }
    },
    eventName: {
      get() {
        var eName = "";
        if (this.events != null && this.form.event != null) {
          eName =
            this.form.language == "HR"
              ? this.events.find(e => e.id == this.form.event).name_hr
              : this.events.find(e => e.id == this.form.event).name_en;
        }
        return eName;
      }
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY.");
    },
    formatAmount(amount) {
      var f = "";
      if (this.form.language == "HR") f = this.$formatterEUR.format(amount);
      else f = this.$formatterEUREn.format(amount);
      if(moment().year()<2024) {
        if (this.form.language == "HR") f = f + ' (' + this.$formatter.format(amount*7.5345) +')';
        else f = f+ ' ('+this.$formatterEn.format(amount*7.5345)+')';
      }
      return f;
    },
    conversionRate() {
      var crate = "";
      if(moment().year()<2024) {
        if (this.form.language == "HR")
          crate = "Fiksni tečaj konverzije: HRK/EUR = 7,53450";
        else
          crate = "Fixed conversion rate: HRK/EUR = 7.53450";
      }
      return crate;
    },
    incStep() {
      if (this.step < 6) this.step++;
      if (
        (this.form.type != 0 && this.step == 6) ||
        (this.form.type == 0 && this.step == 5)
      ) {
        // calculate totalAmount
        var amount = 0;
        for (var i = 0; i < this.form.participants.length; i++)
          if (this.form.participants[i].fee != null)
            amount += parseFloat(
              this.fees.find(e => e.id == this.form.participants[i].fee).amount
            );

        this.totalAmountFloat = amount;
        this.totalAmount = this.formatAmount(amount);
      }
    },
    decStep() {
      if (this.step > 1) this.step--;
    },
    addParticipant() {
      this.form.participants.push({
        id: this.form.participants.length + 1,
        first_name: null,
        last_name: null,
        title: null,
        email: null,
        phone: null,
        address: null,
        city: null,
        postal_code: null,
        country: null,
        fee: null,
        faculty: null,
        index_num: null,
        birthday: null,
        services: []
      });
      this.validParticipantsData.push(true);
    },
    deleteParticipant() {
      if (this.form.participants.length > 1) {
        this.form.participants.pop();
        this.validParticipantsData.pop();
      }
    },
    // get available events
    getEvents() {
      this.$http
        .get("/event/upcomingevents.php", {
          params: {}
        })
        .then(response => {
          this.events = response.data;
        })
        .catch(() => {
          this.events = [];
        });
    },

    // get fees for selected event
    getFees(event) {
      this.$http
        .get("/event/eventfees.php", {
          params: {
            eventid: event
          }
        })
        .then(response => {
          this.fees = response.data;
        })
        .catch(() => {
          this.fees = [];
        });
    },
    // get services for selected event
    getServices(event) {
      this.$http
        .get("/event/eventservices.php", {
          params: {
            eventid: event
          }
        })
        .then(response => {
          this.services = response.data;
        })
        .catch(() => {
          this.services = [];
        });
    },
    register() {
      this.loading = true;
      this.registerSuccessful = false;
      this.registerFailed = false;
      // send data to server
      var me = this;
      this.$vuetify.goTo("#end");
      this.$http
        .post("/event/eventregistration.php", {
          params: {
            form: this.form
          }
        })
        .then(response => {
          me.loading = false;
          if (response.data.success) {
            me.registerSuccessful = true;
            me.registerFailed = false;
            me.uuid = response.data.uuid;
          } else {
            me.registerSuccessful = false;
            me.registerFailed = true;
            me.uuid = null;
          }
          me.$vuetify.goTo("#end");
        })
        .catch(() => {
          me.loading = false;
          me.registerSuccessful = false;
          me.registerFailed = true;
          me.uuid = null;
          me.$vuetify.goTo("#end");
        });
    },
    downloadConfirmation() {
      this.$http
        .get("/event/rform.php", {
          params: {
            uuid: this.uuid
          },
          responseType: "blob"
        })
        .then(response => {
          this.loading = false;
          const fileName = response.headers["x-suggested-filename"];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          this.loading = false;
        });
    },
    downloadQuote() {
      this.$http
        .get("/event/quote.php", {
          params: {
            uuid: this.uuid
          },
          responseType: "blob"
        })
        .then(response => {
          this.loading = false;
          const fileName = response.headers["x-suggested-filename"];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          this.loading = false;
        });
    },
    isYoung(birthday) {
      var retv = false;
      if (birthday != null) {
        var dnow = moment();
        var dbirthday = moment(birthday);
        var df = dnow.diff(dbirthday, "years", true);
        if (df < 35) retv = true;
      }
      return retv;
    }
  },
  mounted() {
    this.getEvents();
  }
};
</script>
<style scoped>
.title {
  height: 200px;
  background: url("../assets/VizualC16.jpg");
  background-repeat: no-repeat;
  background-size: 100vw;
  align-items: flex-end;
  color: white;
  font-size: 28pt !important;
}
.titlesmall {
  height: 200px;
  background: url("../assets/VizualC16.jpg");
  background-repeat: no-repeat;
  background-size: 115vw;
  align-items: flex-end;
  color: white;
  font-size: 16pt !important;
}
.itemtitle {
  white-space: normal;
}
.itemsub {
  white-space: normal;
  -webkit-line-clamp: unset !important;
}
</style>
