<template>
  <div>
    <v-form v-model="validParticipantsData">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-avatar color="secondary">
              <span class="white--text">{{ formParticipants.id + "." }}</span>
            </v-avatar>
            <span class="ml-2 text-h4">{{
              language == "HR" ? "sudionik" : "participant"
            }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" class="py-1">
            <v-text-field
              dense
              :label="language == 'HR' ? 'Titula' : 'Title'"
              :rules="stringRules"
              v-model="formParticipants.title"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6" class="py-1">
            <v-text-field
              dense
              :label="language == 'HR' ? 'Ime *' : 'First name *'"
              :rules="stringRequiredRules"
              v-model="formParticipants.first_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="py-1">
            <v-text-field
              dense
              :label="language == 'HR' ? 'Prezime *' : 'Last name *'"
              :rules="stringRequiredRules"
              v-model="formParticipants.last_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="py-1">
            <v-text-field
              dense
              label="E-mail *"
              :rules="emailRequiredRules"
              v-model="formParticipants.email"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="py-1">
            <v-text-field
              dense
              :label="language == 'HR' ? 'Telefon' : 'Phone'"
              :rules="phoneRules"
              v-model="formParticipants.phone"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="8" class="py-1">
            <div>
              Napomena: nakon što kliknete ispod za upis datuma rođenja te se
              otvori kalendar, za brži odabir datuma, kliknite na godinu u
              gornjem lijevom kutu
            </div>
            <DateInput
              dense
              :label="
                language == 'HR'
                  ? 'Datum rođenja (obavezno za inženjere mlađe od 35 g.)'
                  : 'Birthday (required for engineers younger than 35 years)'
              "
              :model.sync="formParticipants.birthday"
              resetButton
            ></DateInput>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="text-caption">
              {{ language == "HR" ? "* Obavezna polja" : "* Required fields" }}
            </div>
          </v-col>
          <v-col cols="12">
            {{ language == "HR" ? "Kotizacija" : "Fee" }}
            <v-list subheader three-line flat>
              <v-list-item-group v-model="formParticipants.fee">
                <v-list-item v-for="fee in ffees" :key="fee.id" :value="fee.id">
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="primary"
                      ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title
                        class="itemtitle"
                        v-html="
                          (language == 'HR' ? fee.name_hr : fee.name_en) +
                            '<br /><b>' +
                            formatAmount(fee.amount) +
                            '</b>'
                        "
                      >
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="itemsub"
                        v-html="
                          language == 'HR'
                            ? fee.description_hr
                            : fee.description_en
                        "
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <div class="red--text">
              {{
               form.language == "HR"
                                ? "Istaknute cijene su bez PDV-a"
                                : "Prices do not include VAT"
              }}
              <br/>
              {{ conversionRate() }}
            </div>
          </v-col>
          <v-col cols="12">
            {{
              language == "HR"
                ? "Odaberite usluge uključene u kotizaciju:"
                : "Select services included with registration fee:"
            }}
            <v-list flat>
              <v-list-item-group v-model="formParticipants.services" multiple>
                <v-list-item
                  v-for="service in services"
                  :key="service.id"
                  :value="service.id"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="primary"
                      ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title
                        class="itemtitle"
                        v-html="
                          language == 'HR' ? service.name_hr : service.name_en
                        "
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
import DateInput from "@/components/DateInput.vue";
import moment from "moment";

export default {
  props: {
    language: String,
    ccountry: String,
    id: Number,
    fees: Array,
    services: Array,
    form: Object,
    formValid: Boolean
  },
  data() {
    return {
      formParticipants: {
        id: this.id,
        first_name: null,
        last_name: null,
        title: null,
        email: null,
        phone: null,
        address: null,
        city: null,
        postal_code: null,
        country: null,
        fee: null,
        faculty: null,
        index_num: null,
        birthday: null,
        services: []
      },
      maxLen: 200,
      stringRules: [
        v =>
          v
            ? v.length <= this.maxLen ||
              "Maksimalni dozvoljeni broj znakova je " + this.maxLen
            : true
      ],
      stringRequiredRules: [
        v => !!v || "Ovaj podatak je obavezan",
        v =>
          v
            ? v.length <= this.maxLen ||
              "Maksimalni dozvoljeni broj znakova je " + this.maxLen
            : true
      ],
      emailRules: [
        v =>
          v
            ? v.length <= this.maxLen ||
              "Maksimalni dozvoljeni broj znakova je " + this.maxLen
            : true,
        v =>
          v
            ? v.length == 0 ||
              /^([a-zA-Z0-9-_]+|([a-zA-Z0-9-_]+\.){1,2}[a-zA-Z0-9-_]+)@([a-zA-Z0-9-_]+\.){1,3}[a-z]{2,}$/.test(
                v
              ) ||
              "E-mail nije ispravan"
            : true
      ],
      emailRequiredRules: [
        v => !!v || "Potrebno je upisati e-mail adresu",
        v =>
          v
            ? v.length <= this.maxLen ||
              "Maksimalni dozvoljeni broj znakova je " + this.maxLen
            : true,
        v =>
          v
            ? v.length == 0 ||
              /^([a-zA-Z0-9-_]+|([a-zA-Z0-9-_]+\.){1,2}[a-zA-Z0-9-_]+)@([a-zA-Z0-9-_]+\.){1,3}[a-z]{2,}$/.test(
                v
              ) ||
              "E-mail nije ispravan"
            : true
      ],
      phoneRules: [
        v =>
          v
            ? v.length == 0 ||
              v.length <= 30 ||
              "Maksimalni dozvoljeni broj znakova je 30"
            : true,
        v =>
          v
            ? v.length == 0 ||
              /^[0-9\+\- ]+$/.test(v) ||
              "Neispravan broj telefona."
            : true
      ],
      validParticipantsData: true
    };
  },
  components: {
    DateInput
  },
  computed: {
    ffees: {
      get() {
        var flist = [];
        var me = this;
        this.fees.forEach(element => {
          if (
            (me.ccountry == "HR" &&
              me.isYoung(me.formParticipants.birthday) &&
              element.name_hr.indexOf("35") >= 0 &&
              element.amount > 0) ||
            (me.ccountry == "HR" &&
              !me.isYoung(me.formParticipants.birthday) &&
              element.amount > 0 &&
              element.name_hr.indexOf("35") == -1) ||
            (me.ccountry != "HR" &&
              element.amount > 0 &&
              element.name_hr.indexOf("35") == -1)
          ) {
            flist.push(element);
          }
        });
        return flist;
      }
    }
  },
  watch: {
    validParticipantsData(val) {
      this.$emit("update:formValid", val);
    },
    formParticipants: {
      deep: true,
      handler: function(val) {
        this.$emit("update:form", val);
      }
    }
  },
  methods: {
    formatAmount(amount) {
      var f = "";
      if (this.form.language == "HR") f = this.$formatterEUR.format(amount);
      else f = this.$formatterEUREn.format(amount);
      if(moment().year()<2024) {
        if (this.form.language == "HR") f = f + ' (' + this.$formatter.format(amount*7.5345) +')';
        else f = f+ ' ('+this.$formatterEn.format(amount*7.5345)+')';
      }
      return f;
    },
    conversionRate() {
      var crate = "";
      if(moment().year()<2024) {
        if (this.form.language == "HR")
          crate = "Fiksni tečaj konverzije: HRK/EUR = 7,53450";
        else
          crate = "Fixed conversion rate: HRK/EUR = 7.53450";
      }
      return crate;
    },
    isYoung(birthday) {
      var retv = false;
      if (birthday != null) {
        var dnow = moment();
        var dbirthday = moment(birthday);
        var df = dnow.diff(dbirthday, "years", true);
        if (df < 35) retv = true;
      }
      return retv;
    }
  }
};
</script>
<style scoped>
.itemtitle {
  white-space: normal;
}
.itemsub {
  white-space: normal;
  -webkit-line-clamp: unset !important;
}
</style>
